<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          แก้ไขรายการยอดยกมา
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row mb-3 d-flex justify-content-center">
          <label for="input1" class="form-label col-sm-10">ค้นหาสินค้า</label>
          <div class="col-sm-5">
            <input
              v-model="searchProduct"
              type="text"
              class="form-control"
              id="input1"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>

          <div class="col-sm-3">
            <div
              class="
                form-check form-check-inline form-check-custom form-check-solid
              "
            >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="input1"
                id="flexRadioChecked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Item no.</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="input2"
                id="flexRadioChecked2"
                checked="checked"
              />
              <label
                class="form-check-label"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Barcode</label
              >
            </div>
          </div>

          <div class="col-sm-12 col-md-2">
            <button
              type="button"
              class="btn btn-sm btn-light-primary"
              style="font-size: 14px; font-weight: bold; width: 100%"
              width="100%"
              @click="cancel"
            >
              ค้นหา
            </button>
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item no.</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.itemNo"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item detail</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.itemDetail"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Barcode</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.barcode"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center text-start">
          <div class="col-sm-5">
            <label class="form-label">On Hand</label>
            <input
              disabled
              type="text"
              class="form-control mb-4"
              id="inputGroupFile01"
            />
          </div>
          <div class="col-sm-5">
            <label class="form-label">In Transit</label>
            <input
              disabled
              type="text"
              class="form-control mb-4"
              id="inputGroupFile01"
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >POG</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >วันหมดอายุสินค้า</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.receiveDate"
              type="date"
              class="form-control"
              id="receiveDate"
              name="receiveDate"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
              @click="openDatepicker"
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
            >
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  setup() {
    document.title = "J&N | แก้ไขรายการยอดยกมา";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    form: {
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),
  methods: {
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      Swal.fire({
        icon: "success",
        title: "เพิ่มสำเร็จ",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      }).then(() => {
        this.$router.go(-1);
      });
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    openDatepicker() {
      document.getElementById("receiveDate").click();
    },
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
